import React from 'react';
import { Button } from '@vkontakte/vkui';

import Component from '../../classes/component';

class MCButton extends Component {
    render() {
        const buttonAlias = this.getAlias('button');

        return this.insideContainer(
            <Button
                style={buttonAlias && buttonAlias.style}
                size={buttonAlias.getParam('size', 'm')}
                mode={buttonAlias.getParam('mode', 'primary')}
                stretched={buttonAlias.getParam('stretched', false)}
                align={buttonAlias.getParam('align', null)}
                onClick={(e) => this.runAction('click', e)}
            >
                {buttonAlias.getParam('text', '')}
            </Button>
        );
    }
}

export default MCButton;