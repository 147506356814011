import React from 'react';

import Component from '../../classes/component';

class MCLabel extends Component {
    render() {
        const textAlias = this.getAlias('text');

        return this.insideContainer(
            <div
                style={textAlias && textAlias.style}
                onClick={(e) => this.runAction('click', e)}
            >
                {textAlias.getParam('text', '')}
            </div>
        );
    }
}

export default MCLabel;