import React from 'react';
import { PanelHeader, PanelHeaderContent, PanelHeaderBack } from '@vkontakte/vkui';

import Component from '../../classes/component';

class MCHeader extends Component {
    render() {
        const headerAlias = this.getAlias('header');
        const backButtonAlias = this.getAlias('back-button');

        return (
            <PanelHeader
                style={headerAlias && headerAlias.style}
                left={
                    backButtonAlias !== null && backButtonAlias.getParam('show', false) ? (
                        <PanelHeaderBack
                            onClick={() => this.runAction('back')}
                        />
                    ) : null
                }
            >
                <PanelHeaderContent status={headerAlias.getParam('status', null)}>
                    {headerAlias.getParam('title', '')}
                </PanelHeaderContent>
            </PanelHeader>
        );
    }
}

export default MCHeader;