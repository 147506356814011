import React from 'react';

import Component from '../../classes/component';

class MCImage extends Component {
    render() {
        const imageAlias = this.getAlias('image');

        return this.insideContainer(
            <img
                style={imageAlias && imageAlias.style}
                src={imageAlias.getParam('src', null)}
                width={imageAlias.getParam('width', null)}
                height={imageAlias.getParam('height', null)}
                alt="image"
            />
        );
    }
}

export default MCImage;