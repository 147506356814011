import React from 'react';

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.eventListener = props.eventListener || null;
        this.aliases = {};
        this.actions = props.actions || [];
        Object.keys(props.style || {}).forEach((x) => {
            this.aliases[x] = {
                ...(this.aliases[x] || {}),
                style: (props.style || {})[x],
            };
        });

        Object.keys(props.data || {}).forEach((x) => {
            this.aliases[x] = {
                ...(this.aliases[x] || {}),
                data: (props.data || {})[x],
            };
        });
    }

    getAlias(name) {
        if (!this.aliases[name]) return null;

        const alias = this.aliases[name];
        return {
            ...alias,
            style: this.cssParser((alias.style && alias.style[this.props.isSpaceGray ? 'space_gray' : 'bright_light']) || {}),
            getParam: (field, def = undefined) => {
                return alias.data[field] || def;
            },
        };
    }

    runAction(actionName, payload = {}) {
        this.actions.forEach((action) => {
            if (action.action !== actionName) return null;
            if (this.eventListener !== null) {
                this.eventListener(action);
            }
        })
    }

    cssParser(obj) {
        return Object.keys(obj).reduce((a, key) => {
            const newKeyArray = key.split('-');
            let newKey = newKeyArray[0];

            for (let i = 1; i < newKeyArray.length; i++) {
                newKey += newKeyArray[i][0].toUpperCase() + newKeyArray[i].slice(1);
            }

            return {
                ...a,
                [newKey]: obj[key],
            };
        }, {});
    }

    insideContainer(obj) {
        const containerAlias = this.getAlias('container') || {};

        return (
            <div style={containerAlias.style}>
                {obj}
            </div>
        );
    }
}

export default Component;